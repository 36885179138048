import ChatMap from 'pages/hospital/components/ChatMap';
import { useNavigate } from 'react-router-dom';
import * as s from '../../styles/chatbot/style.chatbot';

const ChatbotRecommendation = ({
  message,
  index,
  currentMsg,
  setShowHospital,
  setCurrentKeyword,
  setCurrentMsg,
  language,
}: any) => {
  const navigate = useNavigate();
  const hospitalKeywordList = [
    {
      keyword: '두드러기',
      result: '피부과',
    },
    {
      keyword: '전립선',
      result: '비뇨의학과',
    },
    {
      keyword: '신장',
      result: '신장내과',
    },
    {
      keyword: '방광',
      result: '비뇨의학과',
    },
    {
      keyword: '유방',
      result: '산부인과',
    },
    {
      keyword: '갑상선',
      result: '이비인후과',
    },
  ];

  const welfareKeywordList = [
    {
      keyword: '지원',
      result: '경제지원',
      link: '/inform?field=경제지원&detail=의료비&systemName=본인일부%20부담금%20산정특례%20제도',
    },
    {
      keyword: '경제',
      result: '경제지원',
      link: '/inform?field=경제지원&detail=의료비&systemName=본인일부%20부담금%20산정특례%20제도',
    },
    {
      keyword: '관리',
      result: '암 환자 건강관리',
      link: '/inform?field=암%20환자%20건강관리&detail=이차암예방(검진)&systemName=국가암검진제도',
    },
    {
      keyword: '건강',
      result: '암 환자 건강관리',
      link: '/inform?field=암%20환자%20건강관리&detail=이차암예방(검진)&systemName=국가암검진제도',
    },
    {
      keyword: '간병',
      result: '간병 & 돌봄',
      link: '/field=간병%20%26%20돌봄&detail=간병&systemName=가사/간병%20방문지원사업',
    },
    {
      keyword: '돌봄',
      result: '간병 & 돌봄',
      link: '/inform?field=간병%20%26%20돌봄&detail=간병%20및%20돌봄&systemName=가족돌봄%20휴직',
    },
    {
      keyword: '고용',
      result: '고용 & 취업',
      link: '/inform?field=고용%20%26%20취업&detail=고용&systemName=고용복지플러스센터',
    },
    {
      keyword: '취업',
      result: '고용 & 취업',
      link: '/inform?field=고용%20%26%20취업&detail=고용&systemName=고용복지플러스센터',
    },
  ];

  const matchedHospitalKeywords = hospitalKeywordList.filter((v) =>
    message.includes(v.keyword),
  );
  const matchedWelfareKeywords = welfareKeywordList.filter((v) =>
    message.includes(v.keyword),
  );

  // 중복된 welfare result 값을 제거한 배열 생성
  const uniqueWelfareKeywords = Array.from(
    new Set(matchedWelfareKeywords.map((v) => v.result)),
  ).map((result) => matchedWelfareKeywords.find((v) => v.result === result));

  const recommendHospital = (category: string, index: number) => {
    setShowHospital(true);
    setCurrentKeyword(category);
    setCurrentMsg(index);
  };

  const accessHospitalPage = (category: string) => {
    navigate('/hospital', { state: category });
    setCurrentKeyword(category);
  };

  const accessWelfarePage = (link: string) => {
    navigate(link);
  };

  return (
    <div>
      {matchedHospitalKeywords.map((v) => (
        <div key={`hospital-${index}`}>
          {currentMsg !== index && (
            <s.RecommendButton
              onClick={() => recommendHospital(v.result, index)}
            >
              {language === '한국어'
                ? '주변에 있는 병원이 궁금해요!'
                : 'Recommend Nearby Hospitals'}
            </s.RecommendButton>
          )}
          {currentMsg === index && (
            <>
              <ChatMap />
              <s.RecommendButton onClick={() => accessHospitalPage(v.result)}>
                더 많은 병원을 알고싶어요
              </s.RecommendButton>
            </>
          )}
        </div>
      ))}
      {uniqueWelfareKeywords.map((v: any, index) => (
        <div key={`welfare-${index}`}>
          <s.RecommendWelfareButton onClick={() => accessWelfarePage(v.link)}>
            {v.result}과(와) 관련된 정보 보러가기
          </s.RecommendWelfareButton>
        </div>
      ))}
    </div>
  );
};

export default ChatbotRecommendation;
