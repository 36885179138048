import { create } from "zustand";
import * as T from "../../types/types";

export interface HospitalStoreType {
  hospital: [] | T.PlaceType | [T.PlaceType];
  setHospital: (list: T.PlaceType | [] | [T.PlaceType]) => void;

  userLocation: T.SearchPositionType;
  setUserLocation: (location: T.SearchPositionType) => void;

  searchmenuopen: boolean;
  setSearchMenuOpen: (searchmenuopen: boolean) => void;

  km: number | null;
  setKm: (km: number | null) => void;

  currentKeyword: string;
  setCurrentKeyword: (currentKeyword: string) => void;

  userAddress: string;
  setUserAddress: (userAddress: string) => void;

  currentProvince: string;
  setCurrentProvince: (currentProvince: string) => void;

  currentCity: string;
  setCurrentCity: (currentCity: string) => void;

  currentType: string;
  setCurrentType: (currentType: string) => void;

  currentCategory: string;
  setCurrentCategory: (currentCategory: string) => void;

  totalCategory: string;
  setTotalCategory: (totalCategory: string) => void;

  hospitalDetail: any;
  setHospitalDetail: (hospitalDetail: any) => void;

  hospitalType: string;
  setHospitalType: (hospitalType: string) => void;

  isTypeKeyword: boolean;
  setIsTypeKeyword: (isTypeKeyword: boolean) => void;
}

const useHospitalStore = create<HospitalStoreType>((set) => ({
  hospital: [],
  setHospital: (list: [] | T.PlaceType | [T.PlaceType]) =>
    set({ hospital: list }),

  userLocation: {
    lat: 36.6242237,
    lng: 127.4614843,
  },
  setUserLocation: (location: T.SearchPositionType) =>
    set({ userLocation: location }),

  searchmenuopen: false,
  setSearchMenuOpen: (searchmenuopen: boolean) => set({ searchmenuopen }),

  km: null,
  setKm: (km: number | null) => set({ km }),

  currentKeyword: "",
  setCurrentKeyword: (currentKeyword: string) => set({ currentKeyword }),

  userAddress: "청주",
  setUserAddress: (userAddress: string) => set({ userAddress }),

  currentProvince: "",
  setCurrentProvince: (currentProvince: string) => set({ currentProvince }),

  currentCity: "",
  setCurrentCity: (currentCity: string) => set({ currentCity }),

  currentType: "",
  setCurrentType: (currentType: string) => set({ currentType }),

  currentCategory: "",
  setCurrentCategory: (currentCategory: string) => set({ currentCategory }),

  totalCategory: "검색",
  setTotalCategory: (totalCategory: string) => set({ totalCategory }),

  hospitalDetail: null,
  setHospitalDetail: (hospitalDetail: any) => set({ hospitalDetail }),

  hospitalType: "",
  setHospitalType: (hospitalType: string) => set({ hospitalType }),

  isTypeKeyword: false,
  setIsTypeKeyword: (isTypeKeyword: boolean) => set({ isTypeKeyword }),
}));

export default useHospitalStore;
