import useCurrentTime from "hooks/useCurrentTime";
import React from "react";
import styled from "styled-components";
import useHospitalStore from "../../../zustand/store/hospital";
import { useIsMobile } from "hooks/useIsMobile";
import direction from "assets/vector/hospitalSidebar/direction.svg";

interface BoxProps {
  id: number;
  pharmacyNm: string;
  hospitalNm: string;
  relatedInformation: string;
  address: string;
  kmDistance: number;
  phoneNumber: string;
  medicalInstitutionType: string;
  type: string;
  [key: string]: string | number;
  field: string;
  detailField: string;
  systemName: string;
  lat: number;
  lng: number;
}

const HospitalBox = ({ boxProps }: { boxProps: BoxProps }) => {
  const { setHospital, setHospitalType, setSearchMenuOpen } =
    useHospitalStore();

  const regex = /\((.*?)\)/;

  const isMobile = useIsMobile();

  const [day, dayKr, hours, minutes] = useCurrentTime();

  const handleSearchPosition = (list: any) => {
    setHospitalType(list.type);
    setHospital([list]);
    if (isMobile) {
      setSearchMenuOpen(false);
    }
  };
  return (
    <WelfareHospital key={boxProps.id}>
      <Name onClick={() => handleSearchPosition(boxProps)}>
        {boxProps.hospitalNm}
      </Name>
      <Name onClick={() => handleSearchPosition(boxProps)}>
        {boxProps.pharmacyNm}
      </Name>
      <Name onClick={() => handleSearchPosition(boxProps)}>
        {boxProps.relatedInformation}
      </Name>
      <Institution>{boxProps.medicalInstitutionType}</Institution>
      <Map
        onClick={() =>
          window.open(
            `https://map.kakao.com/link/to/${
              boxProps.hospitalNm
                ? boxProps.hospitalNm
                : boxProps.pharmacyNm
                ? boxProps.pharmacyNm
                : boxProps.relatedInformation
            },${boxProps.lat},${boxProps.lng}`,
          )
        }
      >
        <img src={direction} alt="길찾기" />
        <MapTitle>길찾기</MapTitle>
      </Map>
      <Institution>{boxProps.systemName}</Institution>
      <Address>{boxProps.address}</Address>
      {boxProps[day] && (
        <Time>
          진료시간 {dayKr} {boxProps[day]}
        </Time>
      )}
      {boxProps.kmDistance && <Distance>{boxProps.kmDistance}</Distance>}
      {boxProps.phoneNumber && (
        <Number
          onClick={() =>
            (document.location.href = `tel:${
              boxProps.phoneNumber.match(regex)?.[1]
            }`)
          }
        >
          {boxProps.phoneNumber}
        </Number>
      )}
    </WelfareHospital>
  );
};

export const WelfareHospital = styled.ul`
  border-top: 1px solid #4b4b4b;
  padding: 15px 0px;
  /* cursor: pointer; */
`;

export const Name = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: #2b2b2b;
  cursor: pointer;
  line-height: 120%;
`;

export const Institution = styled.span`
  font-size: 12px;
  margin-left: 3px;
  color: #9d9d9d;
`;

export const Address = styled.p`
  font-size: 13px;
  color: #9d9d9d;
  margin-top: 10px;
`;

export const Time = styled.p`
  font-size: 13px;
  color: #9d9d9d;
  margin-top: 5px;
`;

export const Km = styled.p`
  font-size: 11px;
  color: #9d9d9d;
  margin-top: 10px;
`;

export const Number = styled.p`
  font-size: 13px;
  color: #9d9d9d;
  cursor: pointer;
`;

export const Distance = styled.li`
  font-size: 11px;
  color: #9d9d9d;
  margin-top: 10px;
`;

export const Map = styled.span`
  float: right;
  padding: 3px 5px;
  border: 1px solid #505050;
  border-radius: 3px;
  color: #505050;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  margin-top: 2px;
`;

export const MapTitle = styled.span`
  margin-left: 3px;
`;

export default HospitalBox;
